import React, {useEffect} from 'react'
import Aos from "aos";

import "aos/dist/aos.css";
import './Testimonial.css'
// import pic1 from '../../assets/images/pic-1.png'
// import pic2 from '../../assets/images/pic-2.png'
// import pic3 from '../../assets/images/pic-3.png'
// import pic4 from '../../assets/images/pic-4.png'
const Testimonial = () => {
    useEffect(() => {
        Aos.init();
      }, []);
  return (
    <section className="app__review">

    <div className="app__review-content" data-aos="fade-right" data-aos-delay="300">
        <span>testimonials</span>
        <h3>good news from our clients</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda laudantium corporis fugiat quae unde perspiciatis similique ab modi enim consequatur aperiam cumque distinctio facilis sit, debitis possimus asperiores non harum.</p>
    </div>

    <div className="app__review-box-container" data-aos="fade-left" data-aos-delay="600">

        <div className="app__review-box">
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia, ratione.</p>
            <div className="app__review-user">
                {/* <img src={pic1} alt=""/> */}
                <div className="app__review-info">
                    <h3>john deo</h3>
                    <span>designer</span>
                </div>
            </div>
        </div>
        <div className="app__review-box">
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia, ratione.</p>
            <div className="app__review-user">
                {/* <img src={pic2} alt=""/> */}
                <div className="app__review-info">
                    <h3>john deo</h3>
                    <span>designer</span>
                </div>
            </div>
        </div>
        <div className="app__review-box">
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia, ratione.</p>
            <div className="app__review-user">
                {/* <img src={pic3} alt=""/> */}
                <div className="app__review-info">
                    <h3>john deo</h3>
                    <span>designer</span>
                </div>
            </div>
        </div>
        <div className="app__review-box">
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia, ratione.</p>
            <div className="app__review-user">
                {/* <img src={pic4} alt=""/> */}
                <div className="app__review-info">
                    <h3>john deo</h3>
                    <span>designer</span>
                </div>
            </div>
        </div>

    </div>

</section>

  )
}

export default Testimonial
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_live_51LIJZWDOVGXQRu2gkm067Xvlwmo0oAxYAf4zH6VMJbhenPQpz4maG0PzrhT5UESvVl984lFJJNw2aGGGLVkzPMay00Rqesr0BS"
);

const StripeContainer = () => {
  const { state } = useLocation();
  console.log(state);

  return (
    //
    <div className="checkout-form">
      <Elements
        stripe={stripePromise}
        // theme={appearance}
      >
        <CheckoutForm billingAddress={state} />
      </Elements>
    </div>
  );
};

export default StripeContainer;

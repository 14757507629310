import React, { useState, useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";
import "./Navbar.css";
import { BsTextLeft } from "react-icons/bs";
import sol from "../../assets/images/solteq.jpg";
import trade from "../../assets/images/trade.png";
const Navbar = () => {
  const [isNavbarActive, setIsNavbarActive] = useState(false);

  const handleMenuButtonClick = () => {
    setIsNavbarActive(!isNavbarActive);
  };

  const handleScroll = () => {
    setIsNavbarActive(false);
  };
  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <header className="app__menu-header">
      {/* <i id="menu-btn" className="bi bi-list"></i> */}
      {/* <i id="menu-btn" className="bi bi-list" onClick={handleMenuButtonClick}></i> */}
      <BsTextLeft id="menu-btn" onClick={handleMenuButtonClick} />

      <a
        data-aos="zoom-in-left"
        data-aos-delay="150"
        href="/home"
        className="app__menu-logo"
      >
        <img src={sol} alt="" />
      </a>

      <nav
        className={`app__menu-navbar ${isNavbarActive ? "app__active" : ""}`}
      >
        <a data-aos="zoom-in-left" data-aos-delay="300" href="/home">
          home
        </a>
        <a data-aos="zoom-in-left" data-aos-delay="450" href="/about">
          about
        </a>
        <a data-aos="zoom-in-left" data-aos-delay="600" href="/event">
          event
        </a>
        <a data-aos="zoom-in-left" data-aos-delay="750" href="/services">
          services
        </a>
        <a data-aos="zoom-in-left" data-aos-delay="900" href="/gallery">
          gallery
        </a>
        <a data-aos="zoom-in-left" data-aos-delay="1150" href="/contact">
          contact
        </a>
      </nav>

      <a
        data-aos="zoom-in-left"
        data-aos-delay="1300"
        href="#book-form"
        className="app__menu-logo"
      >
        <img src={trade} alt="" />
      </a>
    </header>
  );
};

export default Navbar;

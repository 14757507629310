import React from "react";
import "./Farm.css";
import Footer from "../../component/Footer/Footer";
const Farm = () => {
  return (
    <div>
      <div className="app__farm">
        <div className="app__farm-img">
          <div className="app__farm-img-content">
            <h4>Farm Management Service</h4>
            <p>
              Welcome to Solteque, where efficient and sustainable farm
              management practices drive our operations. We believe in the power
              of innovation, technology, and thoughtful planning to maximize
              yields while preserving the environment for future generations.
            </p>
          </div>
        </div>

        <div className="app__farm-service">
          <section className="app__farm-services">
            <div className="heading">
              <span>Why Choose Us</span>
              <h1>countless expericences</h1>
            </div>

            <div className="app__farm-box-container">
              <div
                className="app__farm-box"
                data-aos="zoom-in-up"
                data-aos-delay="150"
              >
                <i className="bi bi-globe"></i>
                <h3>Sustainable Practices</h3>
                <p>
                  Our commitment to sustainability is unwavering. We prioritize
                  eco-friendly methods such as crop rotation, natural pest
                  management, and water conservation to minimize our
                  environmental footprint while maintaining soil health and
                  biodiversity.
                </p>
              </div>

              <div
                className="app__farm-box"
                data-aos="zoom-in-up"
                data-aos-delay="300"
              >
                <i className="bi bi-bullseye"></i>
                <h3>Precision Agriculture</h3>
                <p>
                  Embracing cutting-edge technology, we implement precision
                  agriculture techniques to optimize resource usage, reduce
                  waste, and enhance crop quality. Through soil mapping, sensor
                  technology, and data-driven insights, we tailor our farming
                  strategies for maximum efficiency.
                </p>
              </div>

              <div
                className="app__farm-box"
                data-aos="zoom-in-up"
                data-aos-delay="450"
              >
                <i className="bi bi-cpu-fill"></i>
                <h3>Integrated Management Systems</h3>
                <p>
                  Embracing a holistic approach, we integrate various aspects of
                  farm management, including crop planning, irrigation,
                  machinery maintenance, and labor management. This
                  comprehensive strategy ensures smooth operations and
                  consistent productivity
                </p>
              </div>

              <div
                className="app__farm-box"
                data-aos="zoom-in-up"
                data-aos-delay="600"
              >
                <i className="bi bi-feather"></i>
                <h3>Crop Planning</h3>
                <p>
                  Through meticulous planning and analysis, we optimize crop
                  selection, planting schedules, and harvesting techniques to
                  achieve higher yields and better-quality produce
                </p>
              </div>

              <div
                className="app__farm-box"
                data-aos="zoom-in-up"
                data-aos-delay="750"
              >
                <i className="bi bi-file-earmark-medical"></i>
                <h3>Resource Management</h3>
                <p>
                  We employ efficient irrigation systems, responsible fertilizer
                  application, and careful pest and disease control measures to
                  ensure optimal resource utilization while minimizing waste
                </p>
              </div>

              <div
                className="app__farm-box"
                data-aos="zoom-in-up"
                data-aos-delay="900"
              >
                <i className="bi bi-cash-coin"></i>
                <h3>Financial and Risk Management</h3>
                <p>
                  Our farm management practices incorporate financial planning
                  and risk assessment to make informed decisions that secure the
                  farm's economic stability and long-term growth
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Farm;

import React from "react";
import "./Travel.css";
import Footer from "../../component/Footer/Footer";
const Travel = () => {
  return (
    <div>
      <div className="app__travel">
      <div className="app__travel-img">
        <div className="app__travel-img-content">
          <h4>Discover Unforgettable Adventures with Solteque</h4>
          <p>
            Welcome to a world of breathtaking landscapes, vibrant cultures, and
            unforgettable experiences. At Solteque, we specialize in curating
            journeys that inspire, excite, and create lasting memories
          </p>
        </div>
      </div>

      <div className="app__travel-service">
        <section className="app__travel-services">
          <div className="heading">
            <span>Why Choose Us</span>
            <h1>countless expericences</h1>
          </div>

          <div className="app__travel-box-container">
            <div
              className="app__travel-box"
              data-aos="zoom-in-up"
              data-aos-delay="150"
            >
              <i className="bi bi-globe"></i>
              <h3>Explore Diverse Destinations</h3>
              <p>
                From serene beaches to towering mountains, bustling cities to
                remote villages, our itineraries encompass a wide array of
                destinations, each offering its unique charm and allure.
              </p>
            </div>

            <div
              className="app__travel-box"
              data-aos="zoom-in-up"
              data-aos-delay="300"
            >
              <i className="bi bi-binoculars-fill"></i>
              <h3>Tailored Experiences</h3>
              <p>
                Whether you seek adrenaline-pumping adventures, cultural
                immersions, wellness retreats, or leisurely getaways, we craft
                personalized experiences to cater to your preferences
              </p>
            </div>

            <div
              className="app__travel-box"
              data-aos="zoom-in-up"
              data-aos-delay="450"
            >
              <i className="bi bi-person-lines-fill"></i>
              <h3>Expert Guidance</h3>
              <p>
                Our team of experienced guides and travel experts are dedicated
                to ensuring a seamless and enriching travel experience. From
                planning to execution, we're here to make your journey smooth
                and memorable.
              </p>
            </div>

            <div
              className="app__travel-box"
              data-aos="zoom-in-up"
              data-aos-delay="600"
            >
              <i className="bi bi-arrow-left-right"></i>
              <h3>Career Counseling and Guidance</h3>
              <p>
                Our career advisors offer one-on-one counseling sessions to help
                define career paths, set goals, and develop action plans for
                success.
              </p>
            </div>

            <div
              className="app__travel-box"
              data-aos="zoom-in-up"
              data-aos-delay="750"
            >
             <i className="bi bi-feather"></i>
              <h3>Sustainability</h3>
              <p>
                We are committed to responsible travel practices, supporting
                local communities, and minimizing our environmental impact
                wherever we go.
              </p>
            </div>

            <div
              className="app__travel-box"
              data-aos="zoom-in-up"
              data-aos-delay="900"
            >
              <i className="bi bi-gear-fill"></i>
              <h3>Personalized Service</h3>
              <p>
                We prioritize understanding your travel aspirations, ensuring
                that every aspect of your trip is customized to exceed your
                expectations.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Travel;

import React, { useEffect } from 'react'
import Aos from "aos";

import "aos/dist/aos.css";
import './Home.css'
const Home = () => {
  
    useEffect(() => {
        Aos.init();
      }, []);
  return (
  <section className="app__home" id="home">

    <div className="app__home-content">
        {/* <span data-aos="fade-up" data-aos-delay="150">follow us</span>
        <h3 data-aos="fade-up" data-aos-delay="300">Solteque Group Inc.</h3>
        <div data-aos="fade-up" data-aos-delay="450" className="app__home-event">
          <h4 className="app__home-event-title">CANADA TRADE MISSION ’24</h4>
          <p className="app__home-event-heading">(Exhibition, Show & Tourism)</p>
          <ul className="app__home-event-service">
              <li>Local Fabric Makers: <small>Adire, Sanyan, Aso-Ofi & Batik</small></li>
              <li>Local Fabric Designer: <small>Couture, Shoes, Bags.</small></li>
              <li>Local Artisan: <small> Drum, Bead, & Hats</small></li>
              <li>Real Estate: <small>Investors, Realtors, Agents & Developers</small></li>
          </ul>
        </div> */}
        <a data-aos="fade-up" data-aos-delay="600" href="/pricing" className="app__home-btn">get ticket</a>
    </div>
    {/* https://wa.me/+16477008447?text=Get%20ticket%20 */}
</section>
  )
}

export default Home
import React from "react";
import "./Job.css";
import Footer from "../../component/Footer/Footer";
const Job = () => {
  return (
    <div>
      <div className="app__job">
        <div className="app__job-img">
          <div className="app__job-img-content">
            <h4>Your Path to Career Success Starts Here</h4>
            <p>
              Welcome to our job search assistance services! We understand the
              challenges that come with navigating the job market, and we're
              here to support you on your journey toward securing meaningful
              employment and achieving your career goals.
            </p>
          </div>
        </div>

        <div className="app__job-service">
          <section className="app__job-services">
            <div className="heading">
              <span>Why Choose Us</span>
              <h1>countless expericences</h1>
            </div>

            <div className="app__job-box-container">
              <div
                className="app__job-box"
                data-aos="zoom-in-up"
                data-aos-delay="150"
              >
                <i className="bi bi-building"></i>
                <h3>Resume Building and Optimization</h3>
                <p>
                  Our expert team assists in crafting professional resumes that
                  highlight your skills, experiences, and achievements, ensuring
                  they stand out to potential employers
                </p>
              </div>

              <div
                className="app__job-box"
                data-aos="zoom-in-up"
                data-aos-delay="300"
              >
                <i className="bi bi-binoculars-fill"></i>
                <h3>Job Search Strategies</h3>
                <p>
                  We provide personalized guidance on effective job search
                  strategies, including identifying industries, networking tips,
                  and utilizing online job boards and resources.
                </p>
              </div>

              <div
                className="app__job-box"
                data-aos="zoom-in-up"
                data-aos-delay="450"
              >
                <i className="bi bi-person-video"></i>
                <h3>Interview Preparation</h3>
                <p>
                  Prepare with confidence through mock interviews, personalized
                  coaching, and tips on presenting your best self during
                  interviews.
                </p>
              </div>

              <div
                className="app__job-box"
                data-aos="zoom-in-up"
                data-aos-delay="600"
              >
                <i className="bi bi-arrow-left-right"></i>
                <h3>Career Counseling and Guidance</h3>
                <p>
                  Our career advisors offer one-on-one counseling sessions to
                  help define career paths, set goals, and develop action plans
                  for success.
                </p>
              </div>

              <div
                className="app__job-box"
                data-aos="zoom-in-up"
                data-aos-delay="750"
              >
                <i className="bi bi-wifi"></i>
                <h3>Networking Opportunities</h3>
                <p>
                  By cutting out intermediaries, we ensure that our produce
                  travels the shortest distance possible, maintaining its
                  freshness and nutritional v
                </p>
              </div>

              <div
                className="app__job-box"
                data-aos="zoom-in-up"
                data-aos-delay="900"
              >
                <i className="bi bi-person-fill"></i>
                <h3>Expert Guidance:</h3>
                <p>
                  Benefit from the expertise of career professionals who stay
                  updated on industry trends and understand the job market's
                  nuances
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Job;
import React from "react";
import "./Cargo.css"
import Footer from "../../component/Footer/Footer";
const Cargo = () => {
  return (
   <div>
     <div className="app__cargo">
      <div className="app__cargo-img">
        <div className="app__cargo-img-content">
          <h4>CARGO SERVICES</h4>
          <p>
            At Solteque, we offer reliable and efficient cargo services designed
            to transport our premium agricultural produce from our fields
            directly to your location or market. With a focus on freshness,
            timely delivery, and preserving the quality of our harvest, our
            cargo services are tailored to meet your needs.
          </p>
        </div>
      </div>

      <div className="app__cargo-service">
        <section className="app__cargo-services">
          <div className="heading">
            <span>Why Choose Us</span>
            <h1>countless expericences</h1>
          </div>

          <div className="app__cargo-box-container">
            <div
              className="app__cargo-box"
              data-aos="zoom-in-up"
              data-aos-delay="150"
            >
              <i className="bi bi-globe-americas"></i>
              <h3>Timely Delivery</h3>
              <p>
                We understand the importance of prompt deliveries in preserving
                the freshness of agricultural produce. Our streamlined logistics
                ensure that your orders are shipped promptly and efficiently,
                maintaining the quality of the produce from our farm to your
                destination.
              </p>
            </div>

            <div
              className="app__cargo-box"
              data-aos="zoom-in-up"
              data-aos-delay="300"
            >
              <i className="bi bi-shield-check"></i>
              <h3>Specialized Handling</h3>
              <p>
                Our cargo services include specialized handling techniques to
                ensure that delicate fruits, vegetables, and greens are packaged
                and transported with utmost care. We employ proper temperature
                control, packaging materials, and handling procedures to
                preserve freshness during transit.
              </p>
            </div>

            <div
              className="app__cargo-box"
              data-aos="zoom-in-up"
              data-aos-delay="450"
            >
              <i className="bi bi-people-fill"></i>
              <h3>Customized Solutions</h3>
              <p>
                Whether you require small-scale shipments or bulk orders, our
                cargo services are flexible and adaptable to meet your specific
                requirements. We work closely with our clients to provide
                tailored solutions that ensure the safe and timely delivery of
                our premium produce.
              </p>
            </div>

            <div
              className="app__cargo-box"
              data-aos="zoom-in-up"
              data-aos-delay="600"
            >
              <i className="bi bi-shield-check"></i>
              <h3>Quality Assurance</h3>
              <p>
                Our commitment to quality extends beyond the farm. We maintain
                strict quality control measures during the packaging and
                transportation stages to guarantee that our produce arrives at
                your doorstep in optimal condition
              </p>
            </div>

            <div
              className="app__cargo-box"
              data-aos="zoom-in-up"
              data-aos-delay="750"
            >
              <i className="bi bi-truck"></i>
              <h3>Reliability</h3>
              <p>
                Count on us for reliable and dependable cargo services. We
                prioritize efficiency and professionalism in every step of the
                transportation process, ensuring that your orders arrive as
                promised.
              </p>
            </div>

            <div
              className="app__cargo-box"
              data-aos="zoom-in-up"
              data-aos-delay="900"
            >
              <i className="bi bi-person-fill"></i>
              <h3>Sustainability</h3>
              <p>
                We strive to minimize our environmental impact. Our cargo
                services are designed to be eco-friendly, utilizing efficient
                transportation routes and eco-conscious practices whenever
                possible
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
   </div>
  );
};

export default Cargo;

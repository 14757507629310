// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CheckoutForm.css */
.checkout-form {
  width: 50%;
  margin: 20px auto;
  margin-top: 20%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkout-form .CardNumberField-input-wrapper {
  background-color: red;
}

.pay-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
  margin-top: 20px;
}

.pay-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width:816px){

  .checkout-form {
    width: 90%;
   
  }
  }

  @media (max-width:438px){

    .checkout-form {
    margin-top: 123px;
      
    }
    }

`, "",{"version":3,"sources":["webpack://./src/component/Get Ticket/CheckoutForm.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,UAAU;EACV,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;;EAEE;IACE,UAAU;;EAEZ;EACA;;EAEA;;IAEE;IACA,iBAAiB;;IAEjB;IACA","sourcesContent":["/* CheckoutForm.css */\n.checkout-form {\n  width: 50%;\n  margin: 20px auto;\n  margin-top: 20%;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.checkout-form .CardNumberField-input-wrapper {\n  background-color: red;\n}\n\n.pay-button {\n  background-color: #007bff;\n  color: #fff;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  margin: auto;\n  margin-top: 20px;\n}\n\n.pay-button:disabled {\n  background-color: #ccc;\n  cursor: not-allowed;\n}\n\n@media (max-width:816px){\n\n  .checkout-form {\n    width: 90%;\n   \n  }\n  }\n\n  @media (max-width:438px){\n\n    .checkout-form {\n    margin-top: 123px;\n      \n    }\n    }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__gallery{
    overflow: hidden;
}

.app__gallery .app__gallery-box-container {
    columns: 3 25rem;
    column-gap: 1.5rem;
  }
  
  .app__gallery .app__gallery-box-container .app__gallery-box {
    margin-bottom: 1rem;
    page-break-inside: avoid;
    break-inside: avoid;
  }
  
  .app__gallery .app__gallery-box-container .app__gallery-box img {
    width: 100%;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
  
  .app__gallery .app__gallery-box-container .app__gallery-box span i{
    font-size: 1.4rem;
    color: black;
  }
  
  .app__gallery .app__gallery-box-container .app__gallery-box h3 {
    margin-top: .5rem;
    font-size: 2rem;
    color: #fff;
  }

  .app_gallery_more{
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 20px;
  

  }
  `, "",{"version":3,"sources":["webpack://./src/component/Gallery/Gallery.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IAEY,gBAAgB;IAEhB,kBAAkB;EAC5B;;EAEA;IACE,mBAAmB;IAEX,wBAAmB;IAAnB,mBAAmB;EAC7B;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,eAAe;;;EAGjB","sourcesContent":[".app__gallery{\r\n    overflow: hidden;\r\n}\r\n\r\n.app__gallery .app__gallery-box-container {\r\n    -webkit-columns: 3 25rem;\r\n            columns: 3 25rem;\r\n    -webkit-column-gap: 1.5rem;\r\n            column-gap: 1.5rem;\r\n  }\r\n  \r\n  .app__gallery .app__gallery-box-container .app__gallery-box {\r\n    margin-bottom: 1rem;\r\n    -webkit-column-break-inside: avoid;\r\n            break-inside: avoid;\r\n  }\r\n  \r\n  .app__gallery .app__gallery-box-container .app__gallery-box img {\r\n    width: 100%;\r\n    border-radius: 1rem;\r\n    margin-bottom: 1rem;\r\n  }\r\n  \r\n  .app__gallery .app__gallery-box-container .app__gallery-box span i{\r\n    font-size: 1.4rem;\r\n    color: black;\r\n  }\r\n  \r\n  .app__gallery .app__gallery-box-container .app__gallery-box h3 {\r\n    margin-top: .5rem;\r\n    font-size: 2rem;\r\n    color: #fff;\r\n  }\r\n\r\n  .app_gallery_more{\r\n    text-align: center;\r\n    color: black;\r\n    font-weight: bold;\r\n    font-size: 20px;\r\n  \r\n\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'


import gallery2 from "../../assets/images/gallery-2.jpg"
import honorable from "../../assets/images/honorable.jpg"
import headOfService from "../../assets/images/headOfService.jpeg"
import alakeOfEgba from "../../assets/images/alakeOfEgba.jpeg"
import gallery5 from "../../assets/images/gallery-5.jpeg"
import freshFm from "../../assets/images/freshFm.jpeg"


import "./Gallery.css"

const Gallery = () => {
  return (
    <section className="app__gallery" id="gallery">

    <div className="heading">
                <h1>our gallery</h1>
                <span><i>we record memories</i></span>

            </div>
    
    <div className="app__gallery-box-container">

    <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="300">
            <img src={gallery2} alt=""/>
            <span><i>Meeting with the former President of Nigeria, Chief Olusegun Obasanjo after the Pre-Canada Trade Mission Conference</i>
            </span>
        </div>

        <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
            <img src={gallery5} alt=""/>
            <span><i>Visit to Iyaloja of Egbaland with Olu of Orile Itesi, Oba Adeniyi Oluyinka Emulu</i>
            </span>
        </div>

        <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
            <img src={alakeOfEgba} alt=""/>
            <span><i>Courtesy visit to Alake of Egba Land</i>
            </span>
        </div>

      
        <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="450">
            <img src={honorable} alt=""/>
            <span><i>Courtesy visit to the Honorable Comissioner of the Ogun State Ministry of Culture and Tourism and his team</i>
            </span>
        </div>

        <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
            <img src={headOfService} alt=""/>
            <span><i>Meeting with the Ogun State Government Head of Service , Mr.Kolawole Fagbohun</i>
            </span>
        </div>

        <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
            <img src={freshFm} alt=""/>
            <span><i>Visit to Fresh FM 107.9 Studio with Chief Adeyinka Adegbite</i>
            </span>
        </div>

       
    </div>
    <div className="app_gallery_more"><a href="/gallery">more pictures <i className="bi bi-chevron-right"></i></a></div>
</section>
  )
}

export default Gallery
import React from 'react'
import Navbar from '../Navbar/Navbar';
import Home from '../Home/Home';
import About from '../About/About';
import Service from '../Service/Service';
// import Testimonial from '../Testimonial/Testimonial';
import Banner from '../Banner/Banner';
import Countdown from '../Countdown/Countdown';
import Gallery from '../Gallery/Gallery';
import Footer from '../Footer/Footer'
// import Navbar from '../Navbar/Navbar'
const Landing = () => {
  return (
    <div>
      <Navbar/>
      <Home/>
      <Countdown/>
      <About/>
      {/* <Gallery/> */}
     <Service/>
     <Banner/>
      {/* <Testimonial/> */}
      <Gallery/> 
      <Footer/>
    </div>
  )
}

export default Landing
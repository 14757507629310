import React from "react";
import "./Agric.css";
import Footer from "../../component/Footer/Footer";
const Agric = () => {
  return (
   <div>
     <div className="app__agric">
      <div className="app__agric-img">
        <div className="app__agric-img-content">
          <h4>AGRICULTURAL SERVICE</h4>
          <p>
            At Solteque Agricultural Service, we take pride in cultivating the
            finest, freshest, and most wholesome agricultural produce straight
            from our fields to your table. With a deep-rooted commitment to
            sustainable farming practices and a passion for quality, we offer a
            diverse range of premium produce that exemplifies nature's bounty
          </p> 
        </div>
      </div>

      <div className="app__agric-service">
        <section className="app__agric-services" >
          <div className="heading">
            <span>Why Choose Us</span>
            <h1>countless expericences</h1>
          </div>

          <div className="app__agric-box-container">
            <div className="app__agric-box" data-aos="zoom-in-up" data-aos-delay="150">
            <i className="bi bi-globe-americas"></i>
              <h3>Sustainability</h3>
              <p>
              We prioritize sustainable farming methods, incorporating eco-friendly practices to nurture the land and preserve nature's balance.
              </p>
            </div>

            <div className="app__agric-box" data-aos="zoom-in-up" data-aos-delay="300">
            <i className="bi bi-shield-check"></i>
              <h3>Quality Assurance</h3>
              <p>
              Rigorous quality checks at every stage of cultivation and harvesting ensure that only the best produce reaches your plate.

              </p>
            </div>

            <div className="app__agric-box" data-aos="zoom-in-up" data-aos-delay="450">
            <i className="bi bi-people-fill"></i>
              <h3>Community-Centric</h3>
              <p>
              We are deeply embedded in our local community, fostering relationships and supporting local initiatives to create a thriving, interconnected network.
              </p>
            </div>

            <div className="app__agric-box" data-aos="zoom-in-up" data-aos-delay="600">
            <i className="bi bi-backpack4"></i>
              <h3>Diverse Product Range</h3>
              <p>
              At Solteque, we pride ourselves on offering a diverse range of premium agricultural produce
              </p>
            </div>

            <div className="app__agric-box" data-aos="zoom-in-up" data-aos-delay="750">
            <i className="bi bi-truck"></i>
              <h3>Farm-to-Table Promise</h3>
              <p>
              By cutting out intermediaries, we ensure that our produce travels the shortest distance possible, maintaining its freshness and nutritional v
              </p>
            </div>

            <div className="app__agric-box" data-aos="zoom-in-up" data-aos-delay="900">
            <i className="bi bi-person-fill"></i>
              <h3>Customer-Centric Approach</h3>
              <p>
              Your satisfaction is our priority. We take a customer-centric approach, actively seeking feedback and adapting our practices to meet your evolving needs
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
   </div>
  );
};

export default Agric;

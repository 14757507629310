import React from 'react'

import gallery1 from "../../assets/images/gallery-1.jpg"
import gallery2 from "../../assets/images/gallery-2.jpg"
import gallery3 from "../../assets/images/gallery-3.jpg"
import gallery4 from "../../assets/images/gallery-4.jpg"
import gallery5 from "../../assets/images/gallery-5.jpeg"
import gallery6 from "../../assets/images/gallery-6.jpg"
import gallery7 from "../../assets/images/gallery-7.jpg"
import gallery8 from "../../assets/images/gallery-8.jpg"
import gallery9 from "../../assets/images/gallery-9.jpg"
import pict1 from "../../assets/images/pict-1.JPG"
import pict2 from "../../assets/images/pict2.JPG"
import pict3 from "../../assets/images/pict3.JPG"
import pict4 from "../../assets/images/pict4.JPG"
import pict5 from "../../assets/images/pict5.JPG"
import pict6 from "../../assets/images/pict6.JPG"
import pict7 from "../../assets/images/pict7.JPG"
import pict8 from "../../assets/images/pict8.JPG"
import headOfService from "../../assets/images/headOfService.jpeg"
import alakeOfEgba from "../../assets/images/alakeOfEgba.jpeg"
import freshFm from "../../assets/images/freshFm.jpeg"
import vid1 from "../../assets/images/video2.mp4";



import "./Gallery.css"

const Gallery = () => {
    return (
        <section className="app__gallery" id="gallery">

            <div className="heading">
                <span>our gallery</span>
                <h1>we record memories</h1>
            </div>

            <div className="app__gallery-box-container">

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={gallery1} alt="" />
                    {/* <span><i>Meeting with the former President of Nigeria, Chief Olusegun Obasanjoafter the Pre-Canada Trade Mission Conference</i>
            </span>
            <h3>iceland</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={gallery5} alt="" />
                    {/* <span><i>Meeting with the former President of Nigeria, Chief Olusegun Obasanjoafter the Pre-Canada Trade Mission Conference</i>
            </span>
            <h3>iceland</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="300">
                    <img src={gallery2} alt="" />
                    {/* <span>travel spot</span>
            <h3>greenland</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={headOfService} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={freshFm} alt="" />
                    {/* <span>travel spot</span> */}
                </div>


                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={alakeOfEgba} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict1} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict2} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict3} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict4} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict5} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict6} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict7} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={pict8} alt="" />
                    {/* <span>travel spot</span> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="450">
                    <img src={gallery3} alt="" />
                    {/* <span>travel spot</span>
            <h3>alaska</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={gallery4} alt="" />
                    {/* <span>travel spot</span>
            <h3>thailand</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="450">
                    <img src={gallery6} alt="" />
                    {/* <span>travel spot</span>
            <h3>maldive</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="150">
                    <img src={gallery7} alt="" />
                    {/* <span>travel spot</span>
            <h3>iceland</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="300">
                    <img src={gallery8} alt="" />
                    {/* <span>travel spot</span>
            <h3>alaska</h3> */}
                </div>

                <div className="app__gallery-box" data-aos="zoom-in-up" data-aos-delay="450">
                    <img src={gallery9} alt="" />
                    {/* <span>travel spot</span>
            <h3>maldive</h3> */}
                </div>

                <div
                    className="app__about-video-container"
                    data-aos="fade-right"
                    data-aos-delay="300"
                >

                    <video controls  id= "gallery-video" className="app__about-video-video">
                        <source src={vid1} type="video/mp4" />
                    </video>

                </div>

                


            </div>

        </section>
    )
}

export default Gallery
import React, {useEffect} from 'react'

import Aos from "aos";

import "aos/dist/aos.css";
import './Service.css'

import agriculture from '../../assets/images/Agriculture.png'
import cargo from '../../assets/images/Cargo.png'
import job from '../../assets/images/job.png'
import travel from '../../assets/images/Travel.png'
import student from '../../assets/images/student.png'
import farm from '../../assets/images/Farm.png'
const Service = () => {

    useEffect(() => {
        Aos.init();
      }, []);
    
  return (
    <section className="app__destination" id="destination">

    <div className="heading">
       
         <h1>our services</h1>
    </div>

    <div className="app__destination-box-container">

        <div className="app__destination-box" data-aos="fade-up" data-aos-delay="150">
            <div className="app__destination-image">
                <img src={job} alt=""/>
            </div>
            <div className="app__destination-content">
                <h3>Job Search</h3>
                <p>We help clients find the best job, pay, and culture fit for their career ambitions. Our resume building and one on one coaching services are tailored to individual client needs.</p>
                <a href="/job">read more <i className="bi bi-chevron-right"></i></a>
            </div>
        </div>

        <div className="app__destination-box" data-aos="fade-up" data-aos-delay="300">
            <div className="app__destination-image">
                <img src={student} alt=""/>
            </div>
            <div className="app__destination-content">
                <h3>International Student Services</h3>
                <p>We assist students seeking international school admission, travel tickets, and job search services ranging from resume building, interview tips, and one on one coaching.</p>
                <a href="/student">read more <i className="bi bi-chevron-right"></i></a>
            </div>
        </div>

        <div className="app__destination-box" data-aos="fade-up" data-aos-delay="450">
            <div className="app__destination-image">
                <img src={travel} alt=""/>
            </div>
            <div className="app__destination-content">
                <h3>Travel & Tourism</h3>
                <p>We offer a wide range of services to make your travel seamless and enjoyable, including flight and hotel reservations, transportation arrangements, tour bookings, etc.</p>
                <a href="/travel">read more <i className="bi bi-chevron-right"></i></a>
            </div>
        </div>

        <div className="app__destination-box" data-aos="fade-up" data-aos-delay="600">
            <div className="app__destination-image">
                <img src={cargo} alt=""/>
            </div>
            <div className="app__destination-content">
                <h3>Cargo Services (Canada & USA)</h3>
                <p>Our cargo service offers reliable and efficient transportation of goods to Canada and USA. We provide end-to-end tracking and delivery solutions to ensure your cargo arrives safely and on time.</p>
                <a href="/cargo">read more <i className="bi bi-chevron-right"></i></a>
            </div>
        </div>

        <div className="app__destination-box" data-aos="fade-up" data-aos-delay="750">
            <div className="app__destination-image">
                <img src={agriculture} alt=""/>
            </div>
            <div className="app__destination-content">
                <h3>Agricultural Produce</h3>
                <p>We provide customers with fresh, locally grown fruits and vegetables straight from the farm to their doorstep. Our priority is quality and sustainable operations to ensure our customers receive the best possible produce.</p>
                <a href="/agric">read more <i className="bi bi-chevron-right"></i></a>
            </div>
        </div>

        <div className="app__destination-box" data-aos="fade-up" data-aos-delay="900">
            <div className="app__destination-image">
                <img src={farm} alt=""/>
            </div>
            <div className="app__destination-content">
                <h3>Farm Management Services</h3>
                <p>We help farmers optimize their operations for sustainability and profitability with services such as crop planning, soil analysis, animal health inspection, and financial planning,</p>
                <a href="/farm">read more <i className="bi bi-chevron-right"></i></a>
            </div>
        </div>

       

       
    </div>

</section>

  )
}

export default Service
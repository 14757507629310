import React from "react";
import "./Student.css";
import Footer from "../../component/Footer/Footer";
const Student = () => {
  return (
    <div>
      <div className="app__student">
      <div className="app__student-img">
        <div className="app__student-img-content">
          <h4>Welcome to International Student Services at Solteque</h4>
          <p>
            At Solteque, we are dedicated to providing comprehensive support and
            resources to ensure a smooth and enriching experience for our
            international students. Our International Student Services office is
            committed to assisting you in navigating the challenges of studying
            abroad and making your educational journey seamless and rewarding.
          </p>
        </div>
      </div>

      <div className="app__student-service">
        <section className="app__student-services">
          <div className="heading">
            <span>Why Choose Us</span>
            <h1>countless expericences</h1>
          </div>

          <div className="app__student-box-container">
            <div
              className="app__student-box"
              data-aos="zoom-in-up"
              data-aos-delay="150"
            >
              <i className="bi bi-passport-fill"></i>
              <h3>Visa and Immigration Assistance</h3>
              <p>
                Our experienced advisors are here to guide you through the visa
                application process, provide information on immigration
                regulations, and ensure compliance with all necessary
                requirements.
              </p>
            </div>

            <div
              className="app__student-box"
              data-aos="zoom-in-up"
              data-aos-delay="300"
            >
              <i className="bi bi-laptop-fill"></i>
              <h3>Orientation Programs</h3>
              <p>
                We offer tailored orientation sessions to help you acclimate to
                the academic, social, and cultural aspects of life in a new
                country. These programs cover important topics such as academic
                expectations, campus resources, cultural adjustments, and more.
              </p>
            </div>

            <div
              className="app__student-box"
              data-aos="zoom-in-up"
              data-aos-delay="450"
            >
              <i className="bi bi-book-half"></i>
              <h3>Academic Support</h3>
              <p>
                Our academic advisors provide guidance on course selection, and
                academic planning, and offer support services to help you excel
                in your studies.
              </p>
            </div>

            <div
              className="app__student-box"
              data-aos="zoom-in-up"
              data-aos-delay="600"
            >
              <i className="bi bi-arrow-left-right"></i>
              <h3>Cultural Integration</h3>
              <p>
                We organize cultural events, workshops, and activities aimed at
                fostering cross-cultural understanding and integration, allowing
                you to immerse yourself in the local community while preserving
                your cultural identity.
              </p>
            </div>

            <div
              className="app__student-box"
              data-aos="zoom-in-up"
              data-aos-delay="750"
            >
              <i className="bi bi-wifi"></i>
              <h3>Community and Networking</h3>
              <p>
                We facilitate opportunities for international students to
                connect with each other, fostering a supportive community away
                from home
              </p>
            </div>

            <div
              className="app__student-box"
              data-aos="zoom-in-up"
              data-aos-delay="900"
            >
              <i className="bi bi-house-check"></i>
              <h3>Housing Assistance</h3>
              <p>
                We assist in finding suitable accommodation options, whether
                on-campus or off-campus, and provide guidance on housing-related
                matters
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Student;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__footer-main{
    background-color:#000 ;
    color: #fff;
    width: 100%;
   height: 70px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0;
}
.app__contact-content-location{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}
.app__contact-content-location-one h1{
 font-size: 18px;
 color: #000;
 margin-bottom: 15px;
}
.app__contact-content-location-two h1{
    font-size: 18px;
    color: #000;
    margin-bottom: 15px;
}
.app__contact-content-location-one h2{
    font-size: 16px;
    margin-bottom: 15px;
}
.app__contact-content-location-two h2{
    font-size: 16px;
    margin-bottom: 15px;
}
.footer__copy{
    font-size: 18px;
}
@media (max-width: 768px){
    .footer__copy{
        font-size:12px;
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,WAAW;GACZ,YAAY;GACZ,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,SAAS;AACZ;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;AACA;CACC,eAAe;CACf,WAAW;CACX,mBAAmB;AACpB;AACA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI;QACI,cAAc;QACd,kBAAkB;IACtB;AACJ","sourcesContent":[".app__footer-main{\r\n    background-color:#000 ;\r\n    color: #fff;\r\n    width: 100%;\r\n   height: 70px;\r\n   display: flex;\r\n   justify-content: center;\r\n   align-items: center;\r\n   margin: 0;\r\n}\r\n.app__contact-content-location{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    margin-top: 60px;\r\n}\r\n.app__contact-content-location-one h1{\r\n font-size: 18px;\r\n color: #000;\r\n margin-bottom: 15px;\r\n}\r\n.app__contact-content-location-two h1{\r\n    font-size: 18px;\r\n    color: #000;\r\n    margin-bottom: 15px;\r\n}\r\n.app__contact-content-location-one h2{\r\n    font-size: 16px;\r\n    margin-bottom: 15px;\r\n}\r\n.app__contact-content-location-two h2{\r\n    font-size: 16px;\r\n    margin-bottom: 15px;\r\n}\r\n.footer__copy{\r\n    font-size: 18px;\r\n}\r\n@media (max-width: 768px){\r\n    .footer__copy{\r\n        font-size:12px;\r\n        text-align: center;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__gallery{
  overflow: hidden;
}

.app__gallery .app__gallery-box-container {
  columns: 3 25rem;
  column-gap: 1.5rem;
}

.app__gallery .app__gallery-box-container .app__gallery-box {
  margin-bottom: 1rem;
  page-break-inside: avoid;
  break-inside: avoid;
}

.app__gallery .app__gallery-box-container .app__gallery-box img {
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.app__gallery .app__gallery-box-container .app__gallery-box span {
  font-size: 1.4rem;
  color: #aaa;
}

.app__gallery .app__gallery-box-container .app__gallery-box h3 {
  margin-top: .5rem;
  font-size: 2rem;
  color: #fff;
}
.app__about .app__about-video-container {
  flex: 1 1 42rem;
}

.app__about .app__about-video-container .app__about-video-video {
  border-radius: 30px;
  width: 100%;
}

#gallery-video{
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/service/Gallery/Gallery.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EAEU,gBAAgB;EAEhB,kBAAkB;AAC5B;;AAEA;EACE,mBAAmB;EAEX,wBAAmB;EAAnB,mBAAmB;AAC7B;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;AACA;EAGU,eAAe;AACzB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".app__gallery{\n  overflow: hidden;\n}\n\n.app__gallery .app__gallery-box-container {\n  -webkit-columns: 3 25rem;\n          columns: 3 25rem;\n  -webkit-column-gap: 1.5rem;\n          column-gap: 1.5rem;\n}\n\n.app__gallery .app__gallery-box-container .app__gallery-box {\n  margin-bottom: 1rem;\n  -webkit-column-break-inside: avoid;\n          break-inside: avoid;\n}\n\n.app__gallery .app__gallery-box-container .app__gallery-box img {\n  width: 100%;\n  border-radius: 1rem;\n  margin-bottom: 1rem;\n}\n\n.app__gallery .app__gallery-box-container .app__gallery-box span {\n  font-size: 1.4rem;\n  color: #aaa;\n}\n\n.app__gallery .app__gallery-box-container .app__gallery-box h3 {\n  margin-top: .5rem;\n  font-size: 2rem;\n  color: #fff;\n}\n.app__about .app__about-video-container {\n  -webkit-box-flex: 1;\n      -ms-flex: 1 1 42rem;\n          flex: 1 1 42rem;\n}\n\n.app__about .app__about-video-container .app__about-video-video {\n  border-radius: 30px;\n  width: 100%;\n}\n\n#gallery-video{\n  width: 100%;\n  border-radius: 1rem;\n  margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

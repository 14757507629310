import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CheckoutForm = ({ billingAddress }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: billingAddress.name,
        email: billingAddress.email,
        phone: billingAddress.phone,
        // quantity: billingAddress.quantity,
        // unitPrice: billingAddress.unitPrice,
      },
    });

    if (error) {
      console.error("Error:", error);
      setError(error.message);
    } else {
      try {
        const { id } = paymentMethod;
        const response = await axios.post("https://api.soltequegroup.com/payment", {
          amount: billingAddress.price*100 ,
          id,
          description: billingAddress.description,
        });
      
       if(response.data.requires_action && response.data.redirect_to_url){
          window.location.href = response.data.redirect_url;
        }else{
        toast.success(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const appearance = {
    theme: "stripe",
    variables: { colorPrimaryText: "#262626" },
    rules: {
      ".Input": {
        display: "block",
        width: "100%",
      },
    },
  };

  // create card element options
  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        width: "100%",
      },
    },
    layout: {
      type: "tabs",
      defaultCollapsed: true,
    },
    hidePostalCode: true, // You can customize other options based on your needs
  };

  return (
    <form onSubmit={handleSubmit}>
      <ToastContainer />
      <CardElement options={cardElementOptions} theme={appearance} />
      <button type="submit" disabled={!stripe} className="pay-button">
        Pay
      </button>
      {error && <div style={{ color: "red" }}>{error}</div>}
    </form>
  );
};

export default CheckoutForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  transition: all .2s linear;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 9rem;
}


html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background: #111;
}

html::-webkit-scrollbar-thumb {
  background: #e27b33;
  border-radius: 5rem;
}

body {
  background: #f1f1f1;
  overflow-x: hidden;
}

section {
  padding: 3rem 9%;
}

.heading {
  text-align: center;
  margin-bottom: 2rem;
}

.heading span {
  color: #e27b33;
  font-size: 2rem;
}

.heading h1 {
  font-size: 4rem;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading span {
  color: #e27b33;
  text-transform: capitalize;
  font-size: 2rem;
}

.heading h1 {
  font-size: 4rem;
  color: #000;
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,kCAAkC;EAClC,SAAS;EACT,UAAU;EAEF,sBAAsB;EAC9B,aAAa;EACb,YAAY;EACZ,qBAAqB;EAErB,0BAA0B;AAC5B;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,wBAAwB;AAC1B;;;AAGA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE;aACW;AACb;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap\");\n* {\n  font-family: 'Poppins', sans-serif;\n  margin: 0;\n  padding: 0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  outline: none;\n  border: none;\n  text-decoration: none;\n  -webkit-transition: all .2s linear;\n  transition: all .2s linear;\n}\nhtml {\n  font-size: 62.5%;\n  overflow-x: hidden;\n  scroll-behavior: smooth;\n  scroll-padding-top: 9rem;\n}\n\n\nhtml::-webkit-scrollbar {\n  width: 1rem;\n}\n\nhtml::-webkit-scrollbar-track {\n  background: #111;\n}\n\nhtml::-webkit-scrollbar-thumb {\n  background: #e27b33;\n  border-radius: 5rem;\n}\n\nbody {\n  background: #f1f1f1;\n  overflow-x: hidden;\n}\n\nsection {\n  padding: 3rem 9%;\n}\n\n.heading {\n  text-align: center;\n  margin-bottom: 2rem;\n}\n\n.heading span {\n  color: #e27b33;\n  font-size: 2rem;\n}\n\n.heading h1 {\n  font-size: 4rem;\n  color: #000;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.heading span {\n  color: #e27b33;\n  text-transform: capitalize;\n  font-size: 2rem;\n}\n\n.heading h1 {\n  font-size: 4rem;\n  color: #000;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

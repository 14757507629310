import React, { useEffect } from "react";

import Aos from "aos";

import "aos/dist/aos.css";

import vid1 from "../../assets/images/video3.mp4";
// import vid2 from "../../assets/images/about-vid-2.mp4";
// import vid3 from "../../assets/images/about-vid-3.mp4";
// import ReactPlayer from "react-player";

import "./About.css";

const About = () => {
  // const [videoUrl, setVideoUrl] = useState("");

  // useEffect(() => {
  //   const videoElements = document.querySelectorAll(
  //     ".app__about .app__about-video-container .app__about-video-video"
  //   );
  //   const controlButtons = document.querySelectorAll(
  //     ".app__about .app__about-video-container .app__about-controls .app__about-video-control-btn"
  //   );

  //   controlButtons.forEach((button) => {
  //     button.addEventListener("click", () => {
  //       const src = button.getAttribute("data-src");
  //       setVideoUrl(src);

  //       videoElements.forEach((videoElement) => {
  //         videoElement.src = videoUrl;
  //       });
  //     });
  //   });
  // }, []);

  
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="app__about" id="about">
      <div
        className="app__about-video-container"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        {/* <video       src={vid1}         muted
          autoplay
          loop
          className="video"
        > </video> */}
        {/* <ReactPlayer className="app__about-video-video" url='https://www.youtube.com/watch?v=LXb3EKWsInQ' muted   playing="true">
        
        </ReactPlayer> */}

        {/* <video src={vid1} controls className="app__about-video-video" muted autoplay loop  /> */}
        <video  controls  className="app__about-video-video">
        <source src={vid1} type="video/mp4" />
        
      </video>
        {/* <div className="app__about-controls">
          <span
            className="app__about-video-control-btn"
            data-src={vid1}
            onClick={() => setVideoUrl(vid1)}
          ></span>
          <span
            className="app__about-video-control-btn"
            data-src={vid2}
            onClick={() => setVideoUrl(vid2)}
          ></span>
          <span
            className="app__about-video-control-btn"
            data-src={vid3}
            onClick={() => setVideoUrl(vid3)}
          ></span>
        </div> */}
      </div>

      <div
        className="app__about-content"
        data-aos="fade-left"
        data-aos-delay="600"
      >
        <span>why choose us?</span>
        <h3>Why Solteque?</h3>
        <p>
          Welcome to Solteque Group Inc. Your one stop shop to all your
          employment needs. Whether you are seeking temporary or permanent
          recruitment needs, we are dedicated to giving you the very best
          professionals that align to your company values, culture, and
          retention plans. Founded in 2020, Solteque Group Inc. has come a long
          way from its beginning as Leyola Computer in Nigeria. Solteque
          originated from Chief Jimoh Olaleye’s passion for creating a platform
          to help professionals realize and utilize their potentials. With
          inspiration and hard work, Solteque has developed into a versatile and
          strong employment agency. We now serve clients across Canada, US, and
          Nigeria by providing and enabling an environment that thrives on
          diversity and equality.
        </p>
        <a href="/contact" className="app__about-btn">
        Contact us
        </a>
      </div>
    </section>
  );
};

export default About;



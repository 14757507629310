import React from 'react'
import sun from "../../assets/images/contactSun.png"
import "./Contact.css"
import sol from "../../assets/images/globel.png"

const Contacts = () => {
  return (
    <div>
        <div className="app__contacts-main">
        <div className="app__contacts-home">
            <h1 className="app__contacts-title">Lets have a talk</h1>
            <img src={sun} alt="" className="app__contacts-sun"/>
            <img src={sol} alt="" className="app__contacts-sol"/>
            <div className="app__contacts-sun"></div>
            <div className="app__contacts-cloud">

            </div>
        </div>
        <div className="app__contacts-details">
            <div className="app__contacts-map">
                <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=83%20Frenchpark%20Circle,%20Brampton,%20Ontario+(soltequegroup)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a
                        href="https://www.maps.ie/population/">Population mapping</a></iframe>
            </div>

            <div className="app__contacts-touch">
                <h1>Click Here To Contact Us</h1>

                <div className="app__contacts-location">
                    <p><i class="bi bi-telephone"></i> <a href="https://wa.me/+16477008447" className="p-text">+1 647 700 8447</a><br></br><span><p><i class="bi bi-telephone"></i> <a href="https://wa.me/+2348060500013" className="p-text">+2348060500013</a></p></span></p>
                   
                    <p><i class="bi bi-radar"></i> <a href="mailto:admin@soltequegroup.com" className="p-text">admin@soltequegroup.com</a></p>
                    <p><i class="bi bi-geo-alt"></i> <a href="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=90%20Burnhamthorpe%20Rd.%20W,%2014th%20Floor,%20Mississauga,%20Ontario,%20L5B%203C3,%20Canada+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" className="p-text">16 Rutherford Road South, Suite# 203, Brampton, ON, L6W 3J1, Canada</a></p>
                    <p><i class="bi bi-geo-alt"></i> <a href="#" className="p-text">38 N.U.D. Road, Okeyeke, Abeokuta, Ogun State, Nigeria</a></p>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Contacts
import React from "react";
import "./Pricing.css";
import coin1 from "../../assets/images/coin1.png";
import coin2 from "../../assets/images/coin2.png";
import coin3 from "../../assets/images/coin3.png";
import { useNavigate } from "react-router-dom";
const Pricing = () => {
  const navigate = useNavigate();
  return (
    <div className="app__pricings">
      <section className="card container grid">
        {/* <div className="heading">
        <h1>Event Registration</h1>
      </div> */}
        <div className="card__container grid">
          {/* CARD 1 */}
          <article className="card__content grid">
            <div className="card__pricing">
              <div className="card__pricing-number">
                <span className="card__pricing-symbol">$</span>250
              </div>
              <span className="card__pricing-month"></span>
            </div>

            <header className="card__header">
              <div className="card__header-circle grid">
                <img src={coin1} alt="" className="card__header-img" />
              </div>
              {/* <span className="card__header-subtitle">Free plan</span> */}
              <h1 className="card__header-title">Individual</h1>
            </header>

            <ul className="card__list grid">
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Fabric Makers:{" "}
                  <small>Adire, Sanyan, Aso-Ofi & Batik</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Fabric Designer: <small>Couture, Shoes, Bags.</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Artisan: <small> Drum, Bead, & Hats</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Real Estate:{" "}
                  <small>Investors, Realtors, Agents & Developers</small>
                </p>
              </li>
            </ul>
            <button
              className="card__button"
              onClick={() => navigate("/getTicket", { state: { price: 250 } })}
            >
              Get Ticket
            </button>
          </article>

          {/* CARD 2 */}
          <article className="card__content grid">
            <div className="card__pricing">
              <div className="card__pricing-number">
                <span className="card__pricing-symbol">$</span>500
              </div>
              <span className="card__pricing-month"></span>
            </div>

            <header className="card__header">
              <div className="card__header-circle grid">
                <img src={coin2} alt="" className="card__header-img" />
              </div>
              {/* <span className="card__header-subtitle">Most popular</span> */}
              <h1 className="card__header-title">Exhibitor</h1>
            </header>

            <ul className="card__list grid">
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Fabric Makers:{" "}
                  <small>Adire, Sanyan, Aso-Ofi & Batik</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Fabric Designer: <small>Couture, Shoes, Bags.</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Artisan: <small> Drum, Bead, & Hats</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Real Estate:{" "}
                  <small>Investors, Realtors, Agents & Developers</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description"></p>
              </li>
            </ul>

            <button
              className="card__button"
              onClick={() => navigate("/getTicket", { state: { price: 500 } })}
            >
              Get Ticket
            </button>
          </article>

          {/* CARD 3 */}
          <article className="card__content grid">
            <div className="card__pricing">
              <div className="card__pricing-number">
                <span className="card__pricing-symbol">$</span>750
              </div>
              <span className="card__pricing-month"></span>
            </div>

            <header className="card__header">
              <div className="card__header-circle grid">
                <img src={coin3} alt="" className="card__header-img" />
              </div>
              {/* <span className="card__header-subtitle">For agencies</span> */}
              <h1 className="card__header-title">Investor</h1>
            </header>

            <ul className="card__list grid">
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Fabric Makers:{" "}
                  <small>Adire, Sanyan, Aso-Ofi & Batik</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Fabric Designer: <small>Couture, Shoes, Bags.</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Local Artisan: <small> Drum, Bead, & Hats</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description">
                  Real Estate:{" "}
                  <small>Investors, Realtors, Agents & Developers</small>
                </p>
              </li>
              <li className="card__list-item">
                <i className="uil uil-check card__list-icon"></i>
                <p className="card__list-description"></p>
              </li>
            </ul>

            <button
              className="card__button"
              onClick={() => navigate("/getTicket", { state: { price: 750 } })}
            >
              Get Ticket
            </button>
          </article>
        </div>
      </section>
    </div>
  );
};

export default Pricing;

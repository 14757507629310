import React from "react";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./Event.css";
import swper from "../../assets/images/trade.png"
import cana from "../../assets/images/cana.jpg"
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Event= () => {
  return (
    <div className="app_swper">
      <div className="swper-slider">
        <div >
          <Swiper
            spaceBetween={60}
            effect="fade"
            loop={true}
           
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            mousewheel={{
              invert: false,
            }}
            pagination={{
              el: ".swper-slider__pagination",
              clickable: true,
            }}
            className="swper-slider__wrp swiper-wrapper"
          >
            <SwiperSlide className="swper-slider__item swiper-slide">
              <div className="swper-slider__img contain">
                <img
                  src={swper}
                  alt=""
                />
              </div>
              <div className="swper-slider__content">
                <span className="swper-slider__code">19th Of July, 2024</span>
                <div className="swper-slider__title">CANADA TRADE MISSION ’24</div>
                <div className="swper-slider__text">
                (Exhibition, Show & Tourism)
                </div>
                <a href="/pricing" className="swper-slider__button">
                Get Ticket
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swper-slider__item swiper-slide">
              <div className="swper-slider__img">
                <img
                  src={cana}
                  alt=""
                />
              </div>
              <div className="swper-slider__content">
                <span className="swper-slider__code"> 23rd October 2023</span>
                <div className="swper-slider__title">CANADA TRADE MISSION CONFERENCE ’23</div>
                <div className="swper-slider__text">
                (Conference 2023)
                </div>
              </div>
            </SwiperSlide>
          
          </Swiper>
      
        </div>
        <div className="swper-slider__pagination"></div>
      </div>
    </div>
  );
};

export default Event;

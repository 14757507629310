import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./GetTicket.css";
const GetTicket = () => {
  const { state } = useLocation();
  const [count, setCount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [countError, setCountError] = useState("");
  const navigate = useNavigate();

  const incrementCounter = () => {
    setCount(count + 1);
  };

  const decrementCounter = () => {
    setCount(count - 1);
  };

  const validateForm = () => {
    let isValid = true;

    if (!name.trim()) {
      setNameError("Please enter your name.");
      isValid = false;
    } else {
      setNameError("");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

   // const phoneRegex = /^\d{10}$/;
    if (!phone.trim()) {
      setPhoneError("Please enter a valid  phone number.");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (count <= 0) {
      setCountError("Please select a valid quantity.");
      isValid = false;
    } else {
      setCountError("");
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // If all validations pass, navigate to the payment page
      console.log(name,phone,email)
      navigate("/payment", {
        state: {
          name,
          email,
          phone,
          price: count * state.price,
          description: `Purchase of ${count} ticket at a rate of ${state.price} by ${name} with ${phone}`,
        },
      });
    }
  };

  return (
    <div className="app__getTicket">
      <div className="app__getTicket-img">
        <div className="app__getTicket-img-content">
          <h2>Buy Ticket</h2>
        </div>
      </div>

      <div className="app__getTicket-content">
        <div className="app__getTicket-content-form">
          <form action="" className="app__getTicket-form">
            <div className="app__getTicket-form-input">
              <input required
                type="text"
                name="username"
                id="username"
                placeholder="Your Name"
                onChange={(event) => setName(event.target.value)}
              />
              <div className="error-message">{nameError}</div>
            </div>
            <div className="app__getTicket-form-input">
              <input required
                type="email"
                name="useremail"
                id="useremail"
                placeholder="Your Email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <div className="error-message">{emailError}</div>
            </div>
            <div className="app__getTicket-form-input">
              <input required
                type="text"
                name="userphone"
                id=""
                placeholder="Your Phone"
                onChange={(event) => setPhone(event.target.value)}
              />
              <div className="error-message">{phoneError}</div>
            </div>
            <div className="app__getTicket-form-count">
              <h2>Quantity :</h2>
              <div className="app__getTicket-count">
                <a onClick={decrementCounter}>-</a>
                <p>{count}</p>
                <a onClick={incrementCounter}>+</a>
              </div>
              <div className="error-message">{countError}</div>
            </div>
            <div className="app__getTicket-form-btn">
              <button onClick={handleSubmit}>Purchase</button>
            </div>
          </form>
        </div>
        <div className="app__getTicket-content-details">
          <h4 className="app__getTicket-content-heading">
            CANADA TRADE MISSION ’24
          </h4>
          <p className="app__getTicket-content-title">
            (Exhibition, Show & Tourism)
          </p>
          <ul>
            <li>
              Local Fabric Makers: <span>Adire, Sanyan, Aso-Ofi & Batik</span>
            </li>
            <li>
              Local Fabric Designer: <span> Couture, Shoes, Bags.</span>
            </li>
            <li>
              Local Artisan: <span>Drum, Bead, & Hats</span>
            </li>
            <li>
              Real Estate: <span>Investors, Realtors, Agents & Developers</span>
            </li>
          </ul>
          <div className="app__getTicket-details">
            <div className="app__getTicket-detail-event">
              <h4>Details</h4>
              <div className="app__getTicket-detail-start">
                <h4>Start:</h4>
                <p>July 19, 2024</p>
              </div>
              <div className="app__getTicket-detail-end">
                <h4>End:</h4>
                <p>July 24, 2024</p>
              </div>
              <div className="app__getTicket-detail-cost">
                <h4>Cost</h4>
                <p>${state.price}</p>
              </div>
            </div>

            <div className="app__getTicket-venue">
              <h4>VENUE</h4>

              <div className="app__getTicket-venue-address">
                <h4>Address</h4>
                <p>Brampton ON, CANADA</p>
              </div>
              <div className="app__getTicket-venue-map"></div>
              <div className="app__getTicket-venue-phone">
                <h4>Phone:</h4>
                <p>+1 647 700 8447,</p>
                <p>+234 806 050 0013,</p>
                <p>+234 708 029 1737</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetTicket;
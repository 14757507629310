import React, { useEffect, useState } from 'react'
import "./Countdown.css"
import Aos from "aos";

import "aos/dist/aos.css";
const Countdown = () => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Set the date we're counting down to
    const countDownDate = new Date("July 19, 2024 00:00:00").getTime();

    // Update the countdown every 1 second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the time remaining
      const distance = countDownDate - now;

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown state
      setTimeRemaining({ days, hours, minutes, seconds });

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="countdown_box" data-aos="zoom-in" data-aos-delay="150" >
      <div className="countdown_box-container">
        <div className="countdown_count-timer_box" data-aos="zoom-in" data-aos-delay="300">
          <h4 id="days">{timeRemaining.days}</h4>
          <p>Days</p>
        </div>
        <div className="col">:</div>
        <div className="countdown_count-timer_box" data-aos="zoom-in" data-aos-delay="450">
          <h4 id="hours">{timeRemaining.hours}</h4>
          <p>Hours</p>
        </div>
        <div className="col">:</div>
        <div className="countdown_count-timer_box" data-aos="zoom-in" data-aos-delay="600">
          <h4 id="minutes">{timeRemaining.minutes}</h4>
          <p>Minutes</p>
        </div>
        <div className="col">:</div>
        <div className="countdown_count-timer_box" data-aos="zoom-in" data-aos-delay="750"> 
          <h4 id="seconds">{timeRemaining.seconds}</h4>
          <p>Seconds</p>
        </div>
      </div>
    </div>
  )
}

export default Countdown
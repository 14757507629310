import React from "react";
import "./Abouts.css";
import about from "../../assets/images/abouts.jpeg";
import Footer from "../../component/Footer/Footer";
const Abouts = () => {
  return (
    <div>
      <div className="app__abouts">
        <div className="heading">
          <h1 className="app__abouts-heading">About Us</h1>
        </div>
        <div className="app__abouts-content">
          <div className="app__abouts-content-img">
            <img src={about} alt="" />
          </div>
          <div className="app__abouts-content-main">
            <p>
              Welcome to Solteque Group Inc. Your one stop shop to all your
              employment needs. Whether you are seeking temporary or permanent
              recruitment needs, we are dedicated to giving you the very best
              professionals that align to your company values, culture, and
              retention plans.
            </p>
            <p>
              Founded in 2020, Solteque Group Inc. has come a long way from its
              beginning as Leyola Computer in Nigeria. Solteque originated from
              Chief Jimoh Olaleye’s passion for creating a platform to help
              professionals realize and utilize their potentials.
            </p>
            <p>
              With inspiration and hard work, Solteque has developed into a
              versatile and strong employment agency. We now serve clients
              across Canada, US, and Nigeria by providing and enabling an
              environment that thrives on diversity and equality.
            </p>
          </div>
        </div>
        <div className="app__abouts-content-values">
          <div className="app__abouts-content-values-mission">
            <div className="app__abouts-heading-mission">
              <h1>Our Mission</h1>
            </div>
            <p>
              To create a one stop shop for employment needs of businesses and
              organizations. We aim to create an environment where professionals
              develop great work ethics and culture to drive business goals to a
              formidable bottom line and competitive edge.
            </p>
            <div className="app__abouts-heading-vision">
              <h1>Our Vision</h1>
            </div>
            <p>
              To become a global leader in providing workers to companies where
              they can grow and succeed. We strive to improve client
              productivity and business strength.
            </p>
          </div>
          <div className="app__abouts-content-values-core">
            <div className="app__abouts-heading-core">
              <h1>Our Core Values</h1>
            </div>
            <ul>
              <li>Collaboration with Impact</li>
              <li> Customer focus</li>
              <li>Talent retention</li>
              <li>Result driven</li>
              <li>Dignity & Respect</li>
              <li>Diversity & Equality</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Abouts;

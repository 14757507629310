import React, { useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";
import "./Banner.css";

const Banner = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="app__banner">
      <div className="app__content" data-aos="zoom-in-up" data-aos-delay="300">
        <span>start your adventures</span>
        <h3>Let's Explore This World</h3>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
          voluptatum praesentium amet quibusdam quam officia suscipit odio.
        </p> */}
        {/* <a href="#book-form" className="btn">
          book now
        </a> */}

{/* <div id="wrapper">
		<div id="image">
			<div className="image i1"><img src="https://images.pexels.com/photos/258421/pexels-photo-258421.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
			<div className="image i2"><img src="https://images.pexels.com/photos/1382732/pexels-photo-1382732.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
			<div className="image i3"><img src="https://images.pexels.com/photos/704748/pexels-photo-704748.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
			<div className="image i4"><img src="https://images.pexels.com/photos/1122639/pexels-photo-1122639.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
			<div className="image i5"><img src="https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
			<div className="image i6"><img src="https://images.pexels.com/photos/206557/pexels-photo-206557.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
			<div className="image i7"><img src="https://images.pexels.com/photos/1020478/pexels-photo-1020478.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
			<div className="image i8"><img src="https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?auto=compress&cs=tinysrgb&w=600"/></div>
		</div>
	</div> */}
      </div>
    </div>
  );
};

export default Banner;


import "./App.css";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Navbar from "./component/Navbar/Navbar";
import Home from "./component/Home/Home";
import Abouts from "./about/Abouts/Abouts";
import Service from "./component/Service/Service";
import Testimonial from "./component/Testimonial/Testimonial";
import Banner from "./component/Banner/Banner";
import Landing from "./component/Landing/Landing";
import Event from "./component/Event/Event";
import Agric from "./service/Agric/Agric";
import Cargo from "./service/Cargo/Cargo";
import GetTicket from "./component/Get Ticket/GetTicket";
import Farm from "./service/Farm/Farm";
import Job from "./service/Job/Job";
import Student from "./service/Student/Student";
import Gallery from "./service/Gallery/Gallery";
import Travel from "./service/Travel/Travel";
import Contact from "./component/Contact/Contact";
import Pricing from "./component/Pricing/Pricing";
import MainLayout from "./MainLayout";
import StripeCheckout from "./component/Get Ticket/StripeContainer";
import StripeContainer from "./component/Get Ticket/StripeContainer";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Landing />} />
      <Route path="/home" element={<Landing />} />
      <Route path="/about" element={<Abouts />} />
      <Route path="/services" element={<Service />} />
      <Route path="/agric" element={<Agric />} />
      <Route path="/cargo" element={<Cargo />} />
      <Route path="/farm" element={<Farm />} />
      <Route path="/job" element={<Job />} />
      <Route path="/student" element={<Student />} />
      <Route path="/travel" element={<Travel />} />
      <Route path="/getTicket" element={<GetTicket />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/event" element={<Event />} />
      <Route path="/pricing" element={<Pricing />} /> 
      <Route path="/contact" element={<Contact />} />
      <Route path="/payment" element={<StripeContainer />} />
     {/* // <Route path="/event" element={<Pricing />} /> */}
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
    //  <Navbar/>
    //  <Home/>
    //  <About/>
    //  <Service/>
    //  <Banner/>
    //  <Testimonial/>
  );
}

export default App;
import React from 'react'
import "./Footer.css"
const Footer = () => {
  return (
    
    <div className="app__footer">
        <footer className="app__footer-main">
        <p className="footer__copy">Copyright &#169; 2024 Solteque Group Inc.</p>
        </footer>
    </div>
  )
}

export default Footer